<template>
  <div class="box">
    <div class="boxTop">
      <h3>编辑</h3>
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        :key="timer"
      >
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="产品名称：" prop="siteName">
              <el-input
                v-model="form.productName"
                placeholder="请输入产品名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称：" prop="siteName">
              <el-cascader @change="changeProduct" v-model="form.categoryCode" style="width:100%;"
                           :options="productList"
                           :props="{ expandTrigger:'click', value:'categoryCode', label:'categoryName', children:'children'}"
                           :show-all-levels="false"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标题：" prop="siteName">
              <el-input
                v-model="form.title"
                placeholder="请输入标题名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序：" prop="siteName">
              <el-input v-model="form.seq" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="风格：" prop="siteName">
              <el-input v-model="form.style" placeholder="请输入风格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="房型：" prop="siteName">
              <el-input v-model="form.houseType" placeholder="请输入房型"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格：" prop="siteName">
              <el-input v-model="form.price" type="number" placeholder="请输入价格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面图片：" prop="siteName">
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleSuccess"
              >
                <img
                  v-show="form.coverImage"
                  :src="form.coverImage"
                  class="avatar"
                />
                <div v-show="!form.coverImage">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DianbianjiaProductDetails',

  data () {
    return {
      // 表单
      form: {
        productName: '', // 产品名称
        categoryCode: '', // 分类名称
        title: '', // 标题
        seq: '', // 排序
        itemFileUrl: '', // 封面图片
        style: '',  // 风格
        houseType: '',  // 房型
        price: ''   // 价格
      },
      productList: [],   // 产品分类名称
      timer: '',
      productCode: '202306213448230816108',
    }
  },

  mounted () {

  },
  created () {
    this.getProduct()
    this.edit()
  },

  methods: {
    // 产品分类名称选择
    changeProduct (val) {
      // console.log(val);
      if (val && val.length) {
        this.form.categoryCode = val[val.length - 1]
      } else {
        return
      }
    },

    // 获取产品分类
    getProduct () {
      var that = this
      that.$http.post('/productCategory/queryTreeList', that.form)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.productList = that.getTreeList(response.data.data)
          }
        })
    },

    getTreeList (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          data[i].children = undefined
        } else {
          this.getTreeList(data[i].children)
        }
      }
      return data
    },

    // 封面图片上传
    handleSuccess (val) {
      this.form.coverImage = val.data.url
      this.timer = new Date().getTime()
    },

    //修改
    edit (row_productCode) {
      var that = this
      that.$http.post('/product/queryProductDetail', { productCode: that.productCode }).then(function (response) {
        // console.log(response);
        if (response.data.code == 200) {
          that.form = response.data.data
          that.form.categoryCode = response.data.data.categoryCode
        }

      })

    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  width: 98%;
  height: 100%;

  .boxTop {
    width: 100%;
    height: 280px;

    h3 {
      margin: 0;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .avatar-uploader ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    .avatar {
      width: 80px;
      height: 80px;
      display: block;
    }
  }
}
</style>
